import React, { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { isMobile } from "mobile-device-detect";
import HeaderTab from "app/components/shared/HeaderTab";
import Button from "app/components/shared/Button";
import HeaderMenu from "app/components/shared/HeaderMenu";
import ProfileMenu from "app/components/shared/ProfileMenu";
import { getAvatarFromUser } from "app/utils/getUserAvatar";
import Logo from "app/components/shared/Logo";
import useAuth from "app/context/useAuth";
import useBanner from "app/context/useBanner";

import { getBaseRoute, getLoginRoute } from "app/utils/routes";

import "./style.css";

export interface HeaderProps {
  pageKey?: string;
  pageSubKey?: string;
}

function Header(props: HeaderProps) {
  const { user } = useAuth();
  const { banner } = useBanner();

  const history = useHistory();
  const [isTop, setIsTop] = useState(true);
  const [openMenu, setOpenMenu] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const [getStartedMenu, setGetStartedMenu] = useState(false);

  const isAuthenticated = !!user;

  let userAvatar;
  if (user) {
    userAvatar = getAvatarFromUser({
      email: user.email,
      avatar: user.avatar,
    });
  }

  let baseClassName = "app-header";
  let className = baseClassName;
  let getStartedMenuItemClassName = `${baseClassName}__get-started-menu-item`;

  if (isTop) {
    className.replace(` ${baseClassName}--top`, "");
  } else {
    className += ` ${baseClassName}--top`;
  }

  const handleToggleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const handleToggleProfileMenu = () => {
    setOpenProfileMenu(!openProfileMenu);
  };

  const handleToggleGetStartedMenu = () => {
    setGetStartedMenu(!getStartedMenu);
  };

  const handleCloseGetStartedMenu = () => {
    setGetStartedMenu(false);
  };

  useEffect(() => {
    document.addEventListener("scroll", (event: any) => {
      if (event.target.scrollingElement.scrollTop === 0) {
        setIsTop(true);
      } else {
        setIsTop(false);
      }
    });
  }, []);

  let logoUrl = "https://app.evergoal.app";
  switch (process.env.NODE_ENV) {
    case "development":
      logoUrl = "http://localhost:3000";
      break;
    case "test":
      logoUrl = "http://app.evergoal.test";
      break;
    case "production":
      logoUrl = "https://app.evergoal.app";
      break;
    default:
      logoUrl = "https://app.evergoal.app";
      break;
  }

  return openMenu ? (
    <HeaderMenu onClick={handleToggleMenu} />
  ) : (
    <>
      <header className={`${className} text-gray-600 body-font`}>
        <div
          className={`${baseClassName}__nav container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center`}
        >
          <a
            className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0"
            href={logoUrl}
          >
            <Logo className="" />
          </a>

          <div
            className={`${baseClassName}__logo`}
            onClick={() => {
              history.push(getBaseRoute());
            }}
          >
            {/* <LogoIcon className={`${baseClassName}__logo-sm`} /> */}
            {/* <LogoFullIcon className={`${baseClassName}__logo-lg`} /> */}
          </div>
          {/* <nav
          className={`${baseClassName}__nav-left md:ml-auto flex flex-wrap items-left text-base justify-center`}
        >
          {isAuthenticated && (
            <HeaderTab
              selected={props.pageKey === "dashboard"}
              label="Dashboard"
              to={getDashboardRoute()}
            />
          )}
        </nav> */}
          <nav
            className={`${baseClassName}__nav ${baseClassName}__nav-right md:ml-auto flex flex-wrap items-center items-right text-base justify-center`}
          >
            <div
              className={`${baseClassName}__dropdown`}
              // onMouseEnter={!isMobile ? handleToggleGetStartedMenu : null}
              // onMouseLeave={!isMobile ? handleCloseGetStartedMenu : null}
              onClick={(event) => {
                event.stopPropagation();
                handleToggleGetStartedMenu();
              }}
            >
              {/* <Button
            className={`${baseClassName}__dropdown-btn`}
            color="secondary"
            label="Get Started"
            icon={getStartedMenu ? <AngleUp /> : <AngleDown />}
          /> */}
            </div>

            {isAuthenticated ? (
              <Button
                className={`${baseClassName}__menu-btn`}
                color="secondary"
                background="none"
                padding="none"
                icon={
                  <div className="w-12 h-12 rounded-full overflow-hidden border-1 dark:border-white border-gray-900">
                    {userAvatar}
                  </div>
                }
                onClick={handleToggleProfileMenu}
              />
            ) : (
              <HeaderTab
                className={`${baseClassName}__nav-right-link`}
                label="Sign In"
                to={getLoginRoute()}
                onClick={() => {
                  // logFBEvent(FB_EVENTS.XXX);
                }}
              />
            )}

            {openProfileMenu && (
              <ProfileMenu onClick={handleToggleProfileMenu} />
            )}
          </nav>
        </div>
      </header>
      {banner}
    </>
  );
}

export default Header;
