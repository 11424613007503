import md5 from "md5";
import Jdenticon from "react-jdenticon";

export const getAvatarFromUser = (user: { email: string; avatar?: string }) => {
  if (user.avatar) {
    return <img src={user.avatar} alt="user-profile" />;
  }
  return <Jdenticon size="42" value={user.email} />;
  // `https://www.gravatar.com/avatar/${md5(user.email)}?s=200&d=identicon`;
};
