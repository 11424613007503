import { Router, Switch, Redirect, Route } from "react-router-dom";
import { AuthProvider } from "app/context/useAuth";
import { BannerProvider } from "app/context/useBanner";

import {
  getBaseRoute,
  getDashboardRoute,
  getLoginRoute,
  getRequiredUserInfoRoute,
  getSignUpRoute,
  getUserSettingsRoute,
  getEmailVerificationRoute,
  getResetPasswordRoute,
  getRequestEmailVerificationRoute,
  getRequestPasswordResetRoute,
  getAccountActivationRoute,
} from "app/utils/routes";

import history from "app/utils/history";

import "./style.css";
import Header from "app/components/shared/Header";
import Footer from "app/components/shared/Footer";
import PrivateRoute from "app/components/shared/PrivateRoute";
import DashboardPage from "app/components/pages/DashboardPage";
import LoginPage from "app/components/pages/LoginPage";
import SignUpPage from "app/components/pages/SignUpPage";
import RequiredUserInfoPage from "app/components/pages/RequiredUserInfoPage";
import VerifyEmailPage from "app/components/pages/VerifyEmailPage";
import ResetPasswordPage from "app/components/pages/ResetPasswordPage";
import UserSettingsPage from "app/components/pages/UserSettingsPage";
import RequestEmailVerificationPage from "app/components/pages/RequestEmailVerificationPage";
import RequestPasswordResetPage from "app/components/pages/RequestPasswordResetPage";
import ActivateAccountPage from "app/components/pages/ActivateAccountPage";

const baseRoute = getBaseRoute();

function AppLayout() {
  const baseClassName = "app-layout";
  return (
    <Router history={history}>
      <AuthProvider>
        <BannerProvider>
          <Route path={`${baseRoute}/:pageKey?/:pageSubKey?`}>
            <Header />
          </Route>
          <main
            role="main"
            className={`${baseClassName}__content min-h-screen bg-gray-100 py-6 flex flex-col justify-center sm:py-12`}
          >
            <Switch>
              <Redirect exact from="/" to={getDashboardRoute()} />
              {/* <Route exact path={baseRoute}>
              {<DashboardPage />}
            </Route> */}
              <PrivateRoute exact path={getDashboardRoute()}>
                <DashboardPage />
              </PrivateRoute>
              <PrivateRoute exact path={getRequiredUserInfoRoute()}>
                <RequiredUserInfoPage />
              </PrivateRoute>
              <PrivateRoute exact path={getUserSettingsRoute()}>
                <UserSettingsPage />
              </PrivateRoute>

              <Route exact path={getLoginRoute()}>
                <LoginPage />
              </Route>
              <Route exact path={getSignUpRoute()}>
                <SignUpPage />
              </Route>
              <Route exact path={getEmailVerificationRoute()}>
                <VerifyEmailPage />
              </Route>
              <Route exact path={getRequestPasswordResetRoute()}>
                <RequestPasswordResetPage />
              </Route>
              <Route exact path={getRequestEmailVerificationRoute()}>
                <RequestEmailVerificationPage />
              </Route>
              <Route exact path={getResetPasswordRoute()}>
                <ResetPasswordPage />
              </Route>
              <Route exact path={getAccountActivationRoute()}>
                <ActivateAccountPage />
              </Route>
              {/* <Route component={NotFoundPage} /> */}
            </Switch>
          </main>
          <Footer />
        </BannerProvider>
      </AuthProvider>
    </Router>
  );
}

export default AppLayout;
