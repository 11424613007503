export function getBaseRoute() {
  return "";
}

export function getDashboardRoute() {
  return `${getBaseRoute()}/dashboard`;
}

export function getLoginRoute() {
  return `${getBaseRoute()}/login`;
}

export function getSignUpRoute() {
  return `${getBaseRoute()}/sign-up`;
}

export function getRequiredUserInfoRoute() {
  return `${getBaseRoute()}/required-info`;
}

export function getUserSettingsRoute() {
  return `${getBaseRoute()}/user-settings`;
}

export function getEmailVerificationRoute() {
  return `${getBaseRoute()}/verify-email`;
}

export function getRequestEmailVerificationRoute() {
  return `${getBaseRoute()}/request-email-verification`;
}

export function getRequestPasswordResetRoute() {
  return `${getBaseRoute()}/request-password-reset`;
}

export function getResetPasswordRoute() {
  return `${getBaseRoute()}/reset-password`;
}

export function getAccountActivationRoute() {
  return `${getBaseRoute()}/account-activation`;
}

export function getTermsOfServiceRoute() {
  return `${getBaseRoute()}/terms-of-service`;
}
