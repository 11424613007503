import {
  StartSignUpPayload,
  PatchUserPayload,
  VerifyEmailQuery,
  CompleteSignUpPayload,
  RequestEmailVerificationPayload,
  RequestPasswordResetPayload,
  PasswordResetPayload,
} from "@evergoal/types";
import {
  LoginResponse,
  PatchUserResponse,
  GetCurrentUserResponse,
} from "@evergoal/types";

import request from "app/utils/request";
import { getApiUrl } from "app/lib/everaday-client";

export async function getCurrentUser() {
  try {
    const options = {
      method: "GET",
    };
    const url = getApiUrl("api/current-user");
    const results = await request<GetCurrentUserResponse>(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}

export async function verifyEmail(query: VerifyEmailQuery) {
  try {
    const options = {
      method: "POST",
      query,
      skipRefresh: true,
    };
    const url = getApiUrl("api/verify-email");
    const results = await request<void>(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}

export async function completeSignupUser(payload: CompleteSignUpPayload) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      body,
      skipRefresh: true,
    };
    const url = getApiUrl("api/complete-signup");
    await request<void>(url, options);
  } catch (err) {
    throw err;
  }
}

export async function resetPassword(payload: PasswordResetPayload) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      body,
      skipRefresh: true,
    };
    const url = getApiUrl("api/reset-password");
    const results = await request<void>(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}

export async function requestEmailVerification(
  payload: RequestEmailVerificationPayload
) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      body,
      skipRefresh: true,
    };
    const url = getApiUrl("api/request-email-verification");
    const results = await request<void>(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}

export async function requestPasswordReset(
  payload: RequestPasswordResetPayload
) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      body,
      skipRefresh: true,
    };
    const url = getApiUrl("api/request-password-reset");
    const results = await request<void>(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}

export async function patchCurrentUserInfo(payload: PatchUserPayload) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "PATCH",
      body,
    };
    const url = getApiUrl("api/user-info");
    const results = await request<PatchUserResponse>(url, options);
    if (!results) {
      throw new Error("Failed to patch user info");
    }
    return results;
  } catch (err) {
    throw err;
  }
}

export async function startSignUpUser(payload: StartSignUpPayload) {
  try {
    const body = JSON.stringify(payload);
    const options = {
      method: "POST",
      body,
      skipRefresh: true,
    };
    const url = getApiUrl("api/signup");
    await request<void>(url, options);
  } catch (err) {
    throw err;
  }
}

export async function loginUser(email: string, password: string) {
  try {
    const authHeader = btoa(`${email}:${password}`);
    const options = {
      method: "POST",
      headers: {
        Authorization: `Basic ${authHeader}`,
      },
      withCredentials: true,
      skipRefresh: true,
    };
    const url = getApiUrl("api/login");
    const results = await request<LoginResponse>(url, options);
    if (!results) {
      throw new Error("Failed to login");
    }
    return results;
  } catch (err) {
    throw err;
  }
}

export async function logoutUser() {
  try {
    const options = {
      method: "POST",
      withCredentials: true,
    };
    const url = getApiUrl("api/logout");
    await request(url, options);
  } catch (err) {
    throw err;
  }
}

export async function refreshAccessToken() {
  try {
    const options = {
      method: "POST",
      withCredentials: true,
      skipRefresh: true,
    };

    const url = getApiUrl("api/refresh");
    const results = await request(url, options);
    return results;
  } catch (err) {
    throw err;
  }
}
