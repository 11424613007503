import amp from "amplitude-js";

import { getAccessSession } from "app/utils/auth";

enum EventCategory {
  PageView = "page view",
  StateLoad = "state load",
  Click = "click",
}

function createEventProperties(
  page: string,
  action: string,
  component: string | null,
  data: Record<string, any>
) {
  return {
    ...data,
    page, // (ie. "dashboard", "settings", "login")
    component, // (ie. "create habit modal", "habits section")
    action, // (ie. "page view", "click", "success", "error")
  };
}

export function createUserProperties() {
  const accessSession = getAccessSession();
  if (accessSession) {
    return {
      id: accessSession.user.id,
      username: accessSession.user.username,
      email: accessSession.user.email,
      displayName: accessSession.user.displayName,
    };
  }
}

export function initAnalytics(userId?: string) {
  const accessSession = getAccessSession();
  userId = userId || accessSession?.user?.id;
  let hostParts = window.location.hostname.split(".");
  let domainExtension = hostParts[hostParts.length - 1];
  const isDevelopment = domainExtension !== "app";

  console.log("Analytics initalized", domainExtension, isDevelopment);

  // prod
  let apiKey = "06ca2e2d41accf17db249de7455d3bc3";
  if (isDevelopment) {
    // test
    // apiKey = "e4557a3e2432b2a0219b3a15e83f93b3";

    // save events when in dev
    apiKey = "";
  }

  if (userId) {
    amp.getInstance().init(apiKey, userId);
  } else {
    amp.getInstance().init(apiKey);
  }
}

export const logEvent = ({
  page,
  category,
  data = {},
  component = null,
}: {
  page: string;
  category: EventCategory;
  component: string | null;
  data: Record<string, any>;
}) => {
  const eventName = `${category}: ${page}`;
  const eventProperties = createEventProperties(
    page,
    category,
    component,
    data
  );
  amp.getInstance().logEvent(eventName, eventProperties);
};

export const logAnalyticsPageView = ({
  page,
  component = null,
  data = {},
}: {
  page: string;
  component?: string | null;
  data?: Record<string, any>;
}) => {
  logEvent({ page, category: EventCategory.PageView, data, component });
};

export const logAnalyticsStateLoad = ({
  page,
  data = {},
  component = null,
}: {
  page: string;
  data?: Record<string, any>;
  component?: string | null;
}) => {
  logEvent({ page, category: EventCategory.StateLoad, data, component });
};

export const logAnalyticsClick = ({
  page,
  data = {},
  component = null,
}: {
  page: string;
  data?: Record<string, any>;
  component?: string | null;
}) => {
  logEvent({ page, category: EventCategory.Click, data, component });
};

export function setAnalyticsUserId(id: string) {
  amp.getInstance().setUserId(id);
}

export function setAnalyticsUserProperties() {
  const userProperties = createUserProperties();
  if (userProperties) {
    amp.getInstance().setUserProperties(userProperties);
  }
}
