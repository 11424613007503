const config = {
  development: {
    baseUrl: "http://localhost:3000",
  },
  test: {
    baseUrl: "test-api.evergoal.app",
  },
  stage: {
    baseUrl: "stage-api.evergoal.app",
    // Allow stage to hit production db/apis to test changes
    // baseUrl: "api.evergoal.app",
  },
  production: {
    baseUrl: "api.evergoal.app",
  },
};

export const getConfigFromAppUrl = () => {
  const appHostName = window.location.hostname;
  switch (appHostName) {
    case "app.evergoal.app":
      return config.production;
    case "stage.evergoal.app":
      return config.stage;
    case "dev.evergoal.app":
      return config.test;
    default:
      if (appHostName.includes("evergoal-app.pages.dev")) {
        return config.production;
      }
      return config.development;
  }
};

export const getConfigFromEnv = () => {
  const env = process.env.NODE_ENV;
  return config[env || "development"];
};

export const getApiUrl = (url: string) => {
  const config = getConfigFromAppUrl();
  return `${config.baseUrl}/${url}`;
};
