import queryString from "query-string";

import { getAccessSessionTokenFromCookies } from "./auth";

import { HttpException } from "@evergoal/types";

import history from "app/utils/history";
import { refreshAccessToken } from "app/actions/auth";

export const requestRules = {
  TAKE_LAST: "TAKE_LAST",
};

let requestConfig: { [key: string]: any } = {};

let requestCache: any = {};

export function cancelRequestById(id: any) {
  let requestDetails = requestCache[id];

  if (requestDetails) {
    if (requestDetails.controller) {
      requestDetails.controller.abort();
    }
  }
}

export function isFetchCanceled(err: any) {
  if (err.code === 20) {
    return true;
  }

  return false;
}

function parseJSON(response: any) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.json();
}

function parseBlob(response: any) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.blob();
}

function parseArrayBuffer(response: any) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.arrayBuffer();
}

function parseText(response: any) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }

  return response.text();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: any, redirect: boolean) {
  const statusCode = response.status;
  if (statusCode >= 200 && statusCode < 300) {
    return response;
  }
  // if (statusCode === 401) {
  //   if (redirect) {
  //     history.push(`/login`);
  //   }
  //   return response;
  // }
  // if (statusCode === 403) {
  //   // Show error screen?
  //   history.push(`/login`);
  //   return response;
  // }
  if (statusCode === 401) {
    const error = new HttpException(401, "Not authorized");
    throw error;
  }
  if (statusCode >= 500) {
    const error = new HttpException(
      500,
      "Server error, please try again later"
    );
    throw error;
  }

  let error;
  try {
    let responseObj = await response.json();
    // At this point, it should be an error
    const responseData = responseObj.data;
    error = new HttpException(
      statusCode,
      responseData.message,
      responseData.errors
    );
  } catch (err) {
    console.error("Failed to parse error!");
    console.error(err);
    error = new HttpException(500, "Server error, please try again later");
  }
  throw error;
}

function parseURL(url: string) {
  let parser = document.createElement("a");
  let searchObject: any = {};
  let queries;
  let split;
  let i;
  // Let the browser do the work
  parser.href = url;
  // Convert query string to object
  queries = parser.search.replace(/^\?/, "").split("&");
  for (i = 0; i < queries.length; i++) {
    split = queries[i].split("=");
    searchObject[split[0]] = split[1];
  }
  return {
    protocol: parser.protocol,
    host: parser.host,
    hostname: parser.hostname,
    port: parser.port,
    pathname: parser.pathname,
    search: parser.search,
    searchObject,
    hash: parser.hash,
  };
}

export default async function request<T>(
  url: string,
  opts: any,
  redirect: boolean = true
): Promise<T | undefined> {
  if (!url) {
    return;
  }

  let isDomesticDomain = true;
  let parser = parseJSON;
  let fetchPromise = null;
  let requestDetails = null;
  let query = null;
  let body = opts && opts.body;

  let defaultOptions: { [key: string]: any } = {
    method: "GET",
    headers: {
      "Content-Type":
        body instanceof FormData ? "multipart/form-data" : "application/json",
    },
  };

  let options = defaultOptions;

  // start middleware.

  // Middleware
  if (requestConfig.use) {
    requestConfig.use(url, options);
  }

  // Send auth information only for domestic/same domain apis.
  // if (url[0] !== '/') {
  //   isDomesticDomain = false;
  // }

  let parsedUrl = parseURL(url);
  if (window.location.hostname.indexOf("localhost") > -1) {
    isDomesticDomain = true;
  } else if (
    window.location.hostname !== "localhost" &&
    window.location.hostname.indexOf("evergoal.local") < 0
  ) {
    url = `${window.location.protocol}//${url}`;
  } else if (
    url[0] !== "/" &&
    !["evergoal.app"].some((host) => {
      return (
        parsedUrl.hostname.indexOf(host) ===
        parsedUrl.hostname.length - host.length
      );
    })
  ) {
    isDomesticDomain = false;
  }

  let authTokenJwt = getAccessSessionTokenFromCookies();
  // Pre-request refresh if access token has expired
  if (!authTokenJwt && !opts.skipRefresh) {
    await refreshAccessToken();
    authTokenJwt = getAccessSessionTokenFromCookies();
  }

  if (isDomesticDomain) {
    options.credentials = "include";
    options.headers.Authorization = `Bearer ${authTokenJwt}`;
  } else {
    options.credentials = "omit";
  }

  // end middleware

  let computedUrl = url;

  if (opts) {
    options = {
      ...defaultOptions,
      ...opts,
    };
    options.headers = defaultOptions.headers;

    if (opts.headers) {
      options.headers = {
        ...options.headers,
        ...opts.headers,
      };
    }

    if (opts.query && typeof opts.query === "object") {
      query = queryString.stringify(opts.query, {
        arrayFormat: "comma",
        encode: false,
      });
    }

    if (typeof query === "string") {
      if (query[0] !== "?") {
        query = `?${query}`;
      } else {
        query = `${query}`;
      }
    }

    if (query) {
      computedUrl += query;
    }

    if (opts.details) {
      switch (opts.details.responseType) {
        case "blob":
          parser = parseBlob;
          break;
        case "text":
          parser = parseText;
          break;
        case "arrayBuffer":
          parser = parseArrayBuffer;
          break;
        default:
          parser = parseJSON;
          break;
      }

      // Add request caching.
      if (opts.details.id) {
        requestDetails = requestCache[opts.details.id];

        if (requestDetails) {
          // Handle side effects.
          if (opts.details.rule === requestRules.TAKE_LAST) {
            // Only the last requested response of the same id will be handled.
            // Otherwise, an abort error of code 20 will be thrown.
            if (requestDetails.controller) {
              requestDetails.controller.abort();
            }

            requestDetails.controller = new AbortController();
            options.signal = requestDetails.controller.signal;
            requestDetails.fetchPromise = fetch(computedUrl, options);
          }
        } else {
          // Only the last requested response of the same id will be handled.
          // Gives future ability to use the id for debugging features.
          let controller = new AbortController();
          options.signal = controller.signal;
          requestDetails = requestCache[opts.details.id] = {
            controller,
            fetchPromise: fetch(computedUrl, options),
          };
        }

        fetchPromise = requestDetails.fetchPromise;
      }
    }
  }

  // Handle standard fetching.
  if (!fetchPromise) {
    fetchPromise = fetch(computedUrl, options);
  }

  let response = await fetchPromise;
  let responseStatusChecked = await checkStatus(response, redirect);
  let responseParsed = await parser(responseStatusChecked);

  if (requestDetails) {
    requestDetails.controller = null;
    requestDetails.fetchPromise = null;
  }

  return responseParsed as T;
}

export function setRequestConfig(opts: any) {
  if (opts) {
    requestConfig = {
      ...requestConfig,
      ...opts,
    };
  }
}
