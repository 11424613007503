import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

export interface HeaderNavButtonProps {
  label: string;
  to: string;
  onClick?: (event: Object) => void;
  external?: boolean;
  className?: string;
  selected?: boolean;
}

function HeaderNavButton(props: HeaderNavButtonProps) {
  let baseClassName = "app-header-btn";
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  if (props.selected) {
    className += ` ${baseClassName}--selected`;
  }

  return props.external ? (
    <a
      className={`${className} font-bold mr-5 hover-hover:hover:text-gray-900`}
      href={props.to}
      onClick={props.onClick}
    >
      {props.label}
    </a>
  ) : (
    <Link
      className={`${className} font-bold mr-5 hover-hover:hover:text-gray-900`}
      to={props.to}
      onClick={props.onClick}
    >
      {props.label}
    </Link>
  );
}

export default HeaderNavButton;
