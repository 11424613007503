import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
  useMemo,
} from "react";

import { useHistory, useLocation } from "react-router-dom";

import { HttpException } from "@evergoal/types";

import Banner from "app/components/shared/Banner";

interface BannerContextType {
  error: HttpException | null;
  loading: boolean;
  setBannerError: (error: HttpException | null) => void;
  banner: React.ReactElement;
}

const BannerContext = createContext<BannerContextType>({} as BannerContextType);

export function BannerProvider({
  children,
}: {
  children: ReactNode;
}): JSX.Element {
  const [error, setError] = useState<HttpException | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);
  // We are using `react-router` for this example,
  // but feel free to omit this or use the
  // router of your choice.
  const history = useHistory();
  const location = useLocation();

  // If we change page, reset the error state.
  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  const setBannerError = (error: HttpException | null) => {
    setError(error);
  };

  const banner = error ? <Banner error={error}></Banner> : <></>;

  // Make the provider update only when it should.
  // We only want to force re-renders if the user,
  // loading or error states change.
  //
  // Whenever the `value` passed into a provider changes,
  // the whole tree under the provider re-renders, and
  // that can be very costly! Even in this case, where
  // you only get re-renders when logging in and out
  // we want to keep things very performant.
  const memoedValue = useMemo(
    () => ({
      loading,
      error,
      setBannerError,
      banner,
    }),
    [loading, error]
  );

  // We only want to render the underlying app after we
  // assert for the presence of a current user.
  return (
    <BannerContext.Provider value={memoedValue}>
      {children}
    </BannerContext.Provider>
  );
}

export default function useBanner() {
  return useContext(BannerContext);
}
