import { ReactComponent as LogoSvg } from "media/icons/custom/logo.svg";

import "./styles.css";

interface LogoProps {
  className: string;
}

function Logo(props: LogoProps) {
  let baseClassName = "app-logo";
  let className = baseClassName;

  if (props.className) {
    className += ` ${props.className}`;
  }

  return (
    <div className={className}>
      <LogoSvg />
      <p className={`${baseClassName}__label`}>evergoal</p>
    </div>
  );
}

export default Logo;
