import jwtDecode from "jwt-decode";
import * as Cookies from "js-cookie";

import { AccessSession, RefreshSession } from "@evergoal/types";

export function getCookiePrefix(): string {
  switch (process.env.NODE_ENV) {
    case "development":
      return "dev-";
    case "test":
      return "dev-";
    case "production":
      return "";
    default:
      return "dev-";
  }
}

const COOKIE_PREFIX = getCookiePrefix();
export const ACCESS_TOKEN_KEY = `${COOKIE_PREFIX}evergoal-session`;
export const REFRESH_TOKEN_KEY = `${COOKIE_PREFIX}evergoal-refresh`;
export const REFRESH_EXISTS_TOKEN_KEY = `${COOKIE_PREFIX}evergoal-refresh-exists`;
export const DOMAIN_NAME = "evergoal";

export function isJWTValid(jwt: any) {
  if (jwt) {
    const currentTime = Math.floor(Date.now() / 1000);
    if (currentTime < jwt.exp) {
      return true;
    }
  }
  return false;
}

export function getJWT(authToken: string | null) {
  if (authToken) {
    const jwt = jwtDecode(authToken);
    return jwt;
  }
}

export function getRefreshExistsFromCookies() {
  return Cookies.get(REFRESH_EXISTS_TOKEN_KEY);
}

export function hasRefresh() {
  return getRefreshExistsFromCookies() !== undefined;
}

export function getAccessSessionTokenFromCookies() {
  return Cookies.get(ACCESS_TOKEN_KEY);
}

export function getAccessSession(): AccessSession | null {
  try {
    let rawToken = getAccessSessionTokenFromCookies();
    if (rawToken) {
      let decodedAuthToken = getJWT(rawToken);
      return decodedAuthToken as AccessSession;
    }
  } catch (e) {}
  return null;
}

export function setSessionToken(token: string) {
  Cookies.set(ACCESS_TOKEN_KEY, token);
}

export function deleteTokens(tokenIds: string[]) {
  tokenIds.forEach((id) => {
    Cookies.remove(id);
    Cookies.remove(id, { path: "/", domain: `.${DOMAIN_NAME}.app` });
  });
}

export function clearServiceToken() {
  deleteTokens([ACCESS_TOKEN_KEY]);
}

export const clearAuthTokens = () => {
  deleteTokens([ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY]);
};

export const clearCookies = () => {
  Object.keys(Cookies.get()).forEach((cookieName) => {
    Cookies.remove(cookieName);
  });
};
