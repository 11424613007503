import React, { useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { FaTimes } from "react-icons/fa";
import {
  getDashboardRoute,
  getUserSettingsRoute,
  getBaseRoute,
} from "app/utils/routes";

import useAuth from "app/context/useAuth";

import "./style.css";
export interface ProfileMenuProps {
  onClick: () => void;
}

function ProfileMenu(props: ProfileMenuProps) {
  const { logout } = useAuth();
  const outsideClickRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: any) {
      if (
        outsideClickRef &&
        outsideClickRef.current &&
        !outsideClickRef.current.contains(event.target)
      ) {
        props.onClick();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [outsideClickRef]);

  let baseClassName = "app-profile-menu";
  let className = baseClassName;

  return (
    <div
      ref={outsideClickRef}
      className={`${baseClassName} origin-top-right absolute right-0 mr-10 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
    >
      <div className="p-4 hover-hover:hover:bg-gray-50" role="none">
        <Link to={getDashboardRoute()} onClick={props.onClick}>
          Dashboard
        </Link>
      </div>
      <div className="p-4 hover-hover:hover:bg-gray-50" role="none">
        <Link to={getUserSettingsRoute()} onClick={props.onClick}>
          Settings
        </Link>
      </div>
      <div className="p-4 hover-hover:hover:bg-gray-50" role="none">
        <button
          className={`${baseClassName}__nav-right-link`}
          onClick={() => {
            props.onClick();
            logout();
            // logFBEvent(FB_EVENTS.XXX);
          }}
        >
          Logout
        </button>
      </div>
    </div>
  );
}

export default ProfileMenu;
