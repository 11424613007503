import { Link } from "react-router-dom";
import { getDashboardRoute, getUserSettingsRoute } from "app/utils/routes";

import useAuth from "app/context/useAuth";

import "./style.css";
export interface HeaderMenuProps {
  onClick: () => void;
}

function HeaderMenu(props: HeaderMenuProps) {
  const { logout } = useAuth();
  let baseClassName = "app-header-menu";
  let className = baseClassName;

  return (
    <header className={`${className}`}>
      <div
        className={`${baseClassName}__nav origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none`}
      >
        <div className={`${baseClassName}__main-nav`}>
          <Link to={getDashboardRoute()} onClick={props.onClick}>
            Dashboard
          </Link>
          <Link to={getUserSettingsRoute()} onClick={props.onClick}>
            Settings
          </Link>
        </div>
        <div className={`${baseClassName}__sub-nav`}>
          <button
            className={`${baseClassName}__nav-right-link`}
            onClick={() => {
              logout();
              // logFBEvent(FB_EVENTS.XXX);
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </header>
  );
}

export default HeaderMenu;
