import React, { useState, useEffect, ReactNode } from "react";
import { Link } from "react-router-dom";
// import LoaderSpinner from '/app/components/shared/LoadingSpinner';

import "./style.css";

export interface ButtonProps {
  id?: any;
  async?: boolean; // button will show spinner as soon as it is clicked
  className?: string;
  color?: "primary" | "secondary";
  defaultEvents?: boolean;
  disabled?: boolean;
  icon?: ReactNode;
  loading?: boolean; // controls when to hide the spinner and reenable the button
  label?: string;
  layoutDirection?: "rtl";
  newTab?: boolean;
  to?: string;
  background?: string;
  padding?: string;
  variant?: "outlined" | "text";
  type?: "submit" | "button" | "reset";
  onClick?: (event: Object, cProps?: ButtonProps) => void;
  onMouseEnter?: (event: Object, cProps?: ButtonProps) => void;
  onMouseLeave?: (event: Object, cProps?: ButtonProps) => void;
}

function Button(props: ButtonProps) {
  let [loading, setIsLoading] = useState(props.loading);

  let baseClassName = "r-b-btn";
  let className = baseClassName;
  let disabled = props.disabled || loading;

  if (props.layoutDirection === "rtl") {
    className += ` ${baseClassName}-layout--rtl`;
  }

  if (props.variant) {
    className += ` ${baseClassName}-type-${props.variant}`;

    if (props.disabled || loading) {
      className += ` ${baseClassName}-type-${props.variant}--disabled`;
    } else if (props.color) {
      className += ` ${baseClassName}-type-${props.variant}--${props.color}`;
    }
  } else if (props.disabled || loading) {
    className += ` ${baseClassName}--disabled`;
  } else if (props.color) {
    className += ` ${baseClassName}--${props.color}`;
  }

  if (props.className) {
    className += ` ${props.className} `;
  }

  if (props.background) {
    className += ` ${props.background} `;
  } else {
    className += " bg-gray-800 hover-hover:hover:bg-grey-900 ";
  }

  if (props.padding) {
    className += ` ${props.padding} `;
  } else {
    className += " py-2 px-4 ";
  }

  className +=
    " text-white text-sm font-semibold rounded focus:outline-none focus:shadow-outline h-10 disabled:opacity-50 ";

  // let icon = loading ? <LoaderSpinner /> : props.icon;
  let icon = loading ? <div /> : props.icon;

  let handleClick = (event: any) => {
    if (props.async) {
      setIsLoading(true);

      if (props.onClick) {
        props.onClick(event, props);
      }
    } else if (props.onClick) {
      props.onClick(event, props);
    }
  };

  let handleMouseEnter = (event: any) => {
    if (props.onMouseEnter) {
      props.onMouseEnter(event, props);
    }
  };

  let handleMouseLeave = (event: any) => {
    if (props.onMouseLeave) {
      props.onMouseLeave(event, props);
    }
  };

  useEffect(() => {
    setIsLoading(props.loading);
  }, [props.loading]);

  let componentContent = (
    <>
      {icon}
      {props.label && icon && (
        <div className={`${baseClassName}__icon-spacer`} />
      )}
      {props.label}
    </>
  );

  if (props.to) {
    let isExternal = props.to.indexOf("://") >= 0;

    if (props.newTab || isExternal) {
      return (
        <a
          className={className}
          href={props.to}
          target={props.newTab ? "_blank" : "_self"}
          rel="noopener noreferrer"
          onClick={handleClick}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {componentContent}
        </a>
      );
    }

    return (
      <Link
        className={className}
        to={props.to}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {componentContent}
      </Link>
    );
  }

  return (
    <button
      type={props.type || "button"}
      className={className}
      disabled={disabled}
      onClick={handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {componentContent}
    </button>
  );
}

export default Button;
