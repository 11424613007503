import React from "react";
import { Link, useHistory } from "react-router-dom";
import LogoIcon from "media/images/app-logo-symbol-white.svg";
import NameLogoIcon from "media/images/app-logo-symbol-white.svg";
import {
  getBaseRoute,
  getLoginRoute,
  getDashboardRoute,
  getTermsOfServiceRoute,
} from "app/utils/routes";
// import { FB_EVENTS, logFBEvent } from '/utils/fb-pixel';

import "./style.css";

export interface FooterProps {}

function Footer() {
  let history = useHistory();
  let baseClassName = "app-footer";
  let className = baseClassName;

  return (
    <footer className={`${className} bg-gray-800 text-white`}>
      <div className={`${baseClassName}__content`}>
        <div className={`${baseClassName}__left`}>
          <div
            className={`${baseClassName}__logo-area`}
            onClick={() => {
              history.push(getBaseRoute());
            }}
          >
            {/* <LogoIcon className={`${baseClassName}--show-lg`} />
            <NameLogoIcon className={`${baseClassName}--show-sm`} /> */}
          </div>
          <div className={`${baseClassName}__nav`}>
            <nav className={`${baseClassName}__main-nav`}>
              {/* <Link to={getDashboardRoute()}>Dashboard</Link> */}
            </nav>
            <nav className={`${baseClassName}__sub-nav`}>
              {/* <Link to={getTermsOfServiceRoute()}>Terms of Service</Link> */}
            </nav>
          </div>
        </div>
        <div className={`${baseClassName}__right`}></div>
      </div>
      <div className={`${baseClassName}__bottom`}>
        <div className={`${baseClassName}__copyright`}>©2021 evergoal</div>
      </div>
    </footer>
  );
}

export default Footer;
