import { Route, RouteProps } from "react-router-dom";

import useAuth from "app/context/useAuth";

export interface PrivateRouteProps extends RouteProps {
  children: any;
}

function PrivateRoute({ children, ...rest }: PrivateRouteProps) {
  const { user } = useAuth();
  const isAuthenticated = !!user;
  return (
    <Route
      {...rest}
      render={() => {
        return true || isAuthenticated === true ? children : <></>;
      }}
    />
  );
}

export default PrivateRoute;
